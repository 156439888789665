<template>
  <main id="content" role="main" class="overflow-hidden">
    <!-- ========== Hero Section ========== -->
    <div class="position-relative z-index-2 bg-img-hero overflow-hidden" :style="{'background-image': 'url(' + require('@/assets/svg/components/hk-bg-light.jpg') + ')'}">
      <!-- Breadcrumb -->
      <div class="container space-top-2 mt-md-6 pl-0">
        <div class="row justify-content-sm-between mb-5">
          <div class="col-md">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-md-3 mb-0">
                <li class="breadcrumb-item active">
                  <!-- <a href="/events"> -->
                  <router-link :to="{ path: '/events' }">
                    Events
                  </router-link>
                <!-- </a> -->
                </li>
                <li class="breadcrumb-item active" aria-current="page">Apidays Live Hong Kong 2021</li>
              </ol>
            </nav>
          </div>
          <!-- <div class="col-md-auto text-md-right mt-2 ml-3 justify-content-end">
            <a class="btn btn-soft-primary btn-xs" href="/events/apidays-live-hongkong-tc">切換繁體中文 <i class="fas fa-exchange-alt" /></a>
          </div> -->
        </div>
      </div>
      <!-- Title -->
      <div class="container space-bottom-2 position-relative">
        <div class="w-100 w-md-75 w-lg-60 mx-auto text-center">
          <span class="d-block small font-weight-bold text-cap mb-2">August 25 &amp; 26, 2021</span>
          <h1>Apidays Live Hong Kong 2021</h1>
          <h3>API Ecosystem &amp; Data Interchange</h3>
          <img class="img-fluid mt-5" src="@/assets/img/events/apidays-live-hongkong/organisers/apidays-live-hk-color.png" style="max-width:15rem">
        </div>
        <div class="w-lg-75 mx-lg-auto pt-9">
          <div class="card">
            <div class="card-body p-2">
              <div id="VideoPlayer" class="video-player" style="background-color:#f7faff">
                <img class="img-fluid video-player-preview" src="@/assets/img/events/apidays-live-hongkong/2021/images/apidays-live-hongkong-2021-video-cover.jpg">
                <a
                  class="js-inline-video-player video-player-btn video-player-centered"
                  href="javascript:;"
                  data-hs-video-player-options="{
            &quot;videoType&quot;: &quot;vimeo&quot;,
                &quot;videoId&quot;: &quot;605506994&quot;,
                &quot;parentSelector&quot;: &quot;#VideoPlayer&quot;,
                &quot;targetSelector&quot;: &quot;#VideoIframe&quot;,
                &quot;isAutoplay&quot;: true,
                &quot;isMuted&quot;: false,
                &quot;classMap&quot;: {
                &quot;toggle&quot;: &quot;video-player-played&quot;
                }
                }"
                >
                  <span id="ga-event-apidays-2021-opening-video-play-button" class="video-player-icon video-player-icon-primary">
                    <i class="fas fa-play" />
                  </span>
                </a>
                <div class="embed-responsive embed-responsive-16by9">
                  <div id="VideoIframe" />
                </div>
              </div>
            </div>
          </div>
          <p class="text-center font-size-1 mt-2">Apidays Live Hong Kong 2021—Welcome and Opening Remarks By Patrick Fan</p>
        </div>
        <div class="w-lg-85 mx-lg-auto pt-9">
          <div class="card overflow-hidden p-5">
            <div class="row justify-content-md-start align-items-md-center text-center text-md-left z-index-2">
              <div class="col-md-8 col-lg-9 mb-3 mb-md-0 pl-lg-5">
                <h3 class="mb-0">Apidays 2021 playback videos are now available!</h3>
              </div>
              <div class="col-md-4 col-lg-3 text-md-right">
                <a id="ga-event-apidays-2021-watch-now-cta-button" class="btn btn-primary transition-3d-hover" href="javascript:;" data-toggle="modal" data-target="#oahResourcesModal">Watch Now</a>
              </div>
            </div>
            <figure class="w-35 position-absolute top-0 left-0 mt-n11 ml-n11">
              <img class="img-fluid" src="@/assets/svg/components/half-circle-2-light.svg">
            </figure>
          </div>
        </div>
        <!-- <div class="text-center pt-9">
          <a data-gtm-att="ga-event-apidays-2021-register-button" class="btn btn-primary transition-3d-hover mb-3 mb-sm-0 mx-2" href="https://bit.ly/apidayshk2021-registration" target="_blank">Register Now</a>
          <a id="ga-event-apidays-2021-contact-button" class="btn btn-outline-primary mb-3 mb-sm-0 mx-2" href="/contact">Contact Us</a>
        </div> -->
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Organiser Section ========== -->
    <OrganiserBar :list="organiser_list" />
    <!-- ========== End Organiser Section ========== -->

    <!-- ========== Participants Section ========== -->
    <div class="bg-light space-2">
      <div class="container">
        <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-9">
          <span class="d-block small font-weight-bold text-cap mb-2">Participants</span>
          <h2>Already 1500+ Participants from</h2>
        </div>
        <div class="mt-5 text-center">
          <a
            class="js-fancybox media-viewer"
            data-hs-fancybox-options="{
                    &quot;speed&quot;: 700
                }"
            :data-src="require('@/assets/img/events/apidays-live-hongkong/2021/images/apidays-live-hongkong-2021-participants.png')"
            data-caption="Apidays Live Hong Kong 2021 Participants"
            style="cursor: pointer;"
          >
            <img class="img-fluid rounded shadow" src="@/assets/img/events/apidays-live-hongkong/2021/images/apidays-live-hongkong-2021-participants.png" alt="Apidays Live Hong Kong 2021 Participants Logo Wall" data-aos="fade-up">
          </a>
        </div>
      </div>
    </div>
    <!-- ========== End Participants Section ========== -->

    <!-- ========== About Section ========== -->
    <div class="container space-2">
      <div class="row justify-content-lg-between">
        <div class="col-lg-5 order-lg-2 pl-lg-0" data-aos="fade-left">
          <div class="bg-img-hero h-100 min-h-380rem" :style="{'background-image': 'url(' + require('@/assets/img/events/apidays-live-hongkong/2021/images/hongkong.jpg') + ')'}" />
        </div>
        <div class="col-lg-6 order-lg-1">
          <div class="pt-8 pt-lg-0">
            <div class="mb-5 mb-md-7">
              <span class="d-block small font-weight-bold text-cap mb-2">About the Event</span>
              <h2 class="mb-3">API Ecosystem &amp; Data Interchange</h2>
              <p>2020 was a tough year and the pandemic situation accelerated the digital transformation and collaboration across industries in Hong Kong. We have seen a decline in traditional and physical economic activity which lead to a strong push force for many traditional businesses in speeding up API adoptions and data interchange across companies.</p>
              <p>APIs help businesses in different aspects of the economy from banking, e-commerce, logistics, insurance, retail etc. By making the business operations more responsive and automated, businesses can work together to become a more sustainable alliance and better prepared for the unpredictable.</p>
              <p>In order to facilitate effective API connections and secure data interchange, there are increasing challenges for businesses to shift the traditional mindset and way of doing to the “new normal”.</p>
              <p>Apidays Hong Kong 2021 is going to bring together leading API experts both local and international to share their seasonal insights and lessons learnt in driving the team and business under the “new normal”.</p>
              <div class="text-center">
                <h4>Share your feedback with us and watch 2021 replay videos!</h4>
              </div>
            </div>

            <div class="text-center">
              <a id="ga-event-apidays-2021-feedback-button" class="btn btn-primary transition-3d-hover mb-3 mb-sm-0 mx-2" href="https://bit.ly/apidayshk-2021-attendee-survey" target="_blank">Share Feedback</a>
              <a id="ga-event-apidays-2021-globalsite-button" class="btn btn-outline-primary mb-3 mb-sm-0 mx-2" href="https://www.apidays.global/" target="_blank">Apidays Global Site</a>
            </div>
          </div>
        </div>
      </div>

      <!-- More features -->
      <!-- <div class="row mt-11">
        <div class="col-md-3 col-6 mb-3 mb-md-5">
          <div class="pr-lg-4">
            <span class="h2 text-primary">1000</span>
            <span class="h2 text-primary">+</span>
            <p>attendees all over the globe</p>
          </div>
        </div>

        <div class="col-md-3 col-6 mb-3 mb-md-5">
          <div class="pr-lg-4">
            <span class="h2 text-primary">600</span>
            <span class="h2 text-primary">+</span>
            <p>companies in different sectors</p>
          </div>
        </div>

        <div class="col-md-3 col-6">
          <div class="pr-lg-4">
            <span class="h2 text-primary">45</span>
            <p>sessions about various topics on Open API Economy</p>
          </div>
        </div>

        <div class="col-md-3 col-6">
          <div class="pr-lg-4">
            <span class="h2 text-primary">52</span>
            <p>speakers from different professional roles</p>
          </div>
        </div>
      </div> -->
      <!-- End More features -->
    </div>
    <!-- ========== End About Section ========== -->

    <!-- ========== Speakers Section ========== -->
    <div class="bg-light space-2">
      <div class="container">
        <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-5 mb-md-9">
          <span class="d-block small font-weight-bold text-cap mb-2">Our Speakers</span>
          <h2>Speaker Lineup</h2>
        </div>

        <!-- Filters -->
        <!-- <input v-for="list in speaker_list" :id="list.group" :key="list.group" v-model="selected_group" type="radio" name="categories" :value="list.group">

        <ol class="filters-btn" style="margin-left: 1rem; overflow: auto; white-space: nowrap; -webkit-overflow-scrolling: touch;">
          <li v-for="list in speaker_list" :key="list.group">
            <label class="btn btn-sm btn-soft-primary btn-pill py-2 px-3 m-1" :class="{'selectedGroup': selected_group === list.group}" :for="list.group">{{ list.title }}</label>
          </li>
        </ol> -->
        <!-- End Filters -->

        <span v-for="list in speaker_list" :key="`${list.group}_speaker`">
          <ol v-if="list.group === selected_group" class="filters-container row">
            <!-- Speaker -->
            <li v-for="speaker in list.speaker" :key="speaker.name" class="col-12 col-sm-6 col-lg-3 px-2 mb-3 filters-item" :data-category="list.group">
              <a class="card h-100 transition-3d-hover" data-toggle="modal" :data-target="'#' + speaker.data_target" style="cursor:pointer">
                <div class="card-body">
                  <div class="avatar avatar-xl avatar-circle mb-4" style="border: 2px solid #f4f5fa">
                    <img class="avatar-img" :src="require('@/assets/img/events/apidays-live-hongkong/2021/speakers/' + speaker.image_url)">
                  </div>
                  <h3>{{ speaker.name }}</h3>
                  <p>{{ speaker.job_title }}</p>
                </div>
              </a>
              <!-- Modal -->
              <div :id="speaker.data_target" class="modal fade" tabindex="-1" role="dialog" :aria-labelledby="speaker.data_target + 'Title'" aria-hidden="true">
                <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <!-- Header -->
                    <div class="modal-top-cover bg-primary text-center">
                      <figure class="position-absolute right-0 bottom-0 left-0">
                        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                        </svg>
                      </figure>

                      <div class="modal-close">
                        <button type="button" class="btn btn-icon btn-sm btn-ghost-light" data-dismiss="modal" aria-label="Close">
                          <svg width="16" height="16" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                          </svg>
                        </button>
                      </div>

                      <figure class="w-100 position-absolute bottom-0 left-0">
                        <img class="img-fluid" src="@/assets/svg/components/isometric-lego.svg">
                      </figure>
                    </div>
                    <!-- End Header -->

                    <!-- Speaker Profile  -->
                    <div class="modal-top-cover-avatar">
                      <img class="avatar avatar-xl avatar-circle avatar-border-lg avatar-centered shadow-soft" :src="require('@/assets/img/events/apidays-live-hongkong/2021/speakers/' + speaker.image_url)">
                    </div>
                    <div class="text-center m-3 mx-5">
                      <h2 class="mb-0">{{ speaker.name }}</h2>
                      <p v-if="speaker.company" class="d-block font-weight-bold mb-1">{{ speaker.company }}</p>
                      <p>{{ speaker.position }}</p>
                      <!-- <a :href="require('@/assets/img/events/apidays-live-hongkong/speakers/' + speaker.media_card)" class="btn btn-xs btn-soft-secondary" download>
                        <i class="fas fa-download mr-1" /> Download Social Media Card
                      </a> -->
                    </div>
                    <!-- End Speaker Profile  -->

                    <!-- Body -->
                    <div class="modal-body pb-sm-5 px-sm-5">
                      <div v-if="speaker.about" class="row mb-6">
                        <div class="col-12 col-lg-3 mb-1">
                          <h5 class="text-cap" style="color:#999999">about</h5>
                        </div>
                        <div class="col-12 col-lg-9">
                          <p v-for="item in speaker.about" :key="item">
                            {{ item }}
                          </p>
                          <div v-if="speaker.about_special" v-html="speaker.about_special" />
                        </div>
                      </div>

                      <div v-if="speaker.topic_title" class="row mb-6">
                        <div class="col-12 col-lg-3 mb-1">
                          <h5 class="text-cap" style="color:#999999">speaking topic</h5>
                        </div>
                        <div class="col-12 col-lg-9">
                          <h3 class="mb-2">{{ speaker.topic_title }}</h3>
                          <span v-if="speaker.topic_description">
                            <p v-for="item in speaker.topic_description" :key="item">
                              {{ item }}
                            </p>
                          </span>
                          <div v-if="speaker.topic_special" v-html="speaker.topic_special" />
                          <div v-if="speaker.topic_title_2" class="mt-6">
                            <h3 class="mb-2">{{ speaker.topic_title_2 }}</h3>
                            <span v-if="speaker.topic_description_2">
                              <p v-for="item in speaker.topic_description_2" :key="item">
                                {{ item }}
                              </p>
                            </span>
                            <div v-if="speaker.topic_special_2" v-html="speaker.topic_special_2" />
                          </div>
                        </div>
                      </div>

                      <div v-if="(speaker.linkedin || speaker.twitter)" class="row mb-6">
                        <div class="col-12 col-lg-3 mb-1">
                          <h5 class="text-cap" style="color:#999999">get connected</h5>
                        </div>
                        <div class="col-12 col-lg-9">
                          <ul class="list-inline mb-0">
                            <li v-if="speaker.linkedin" class="list-inline-item">
                              <a class="btn btn-sm btn-icon btn-soft-secondary rounded" :href="'https://www.linkedin.com/in/' + speaker.linkedin + '/'" target="_blank">
                                <i class="fab fa-linkedin" />
                              </a>
                            </li>
                            <li v-if="speaker.twitter" class="list-inline-item">
                              <a class="btn btn-sm btn-icon btn-soft-secondary rounded" :href="'https://twitter.com/' + speaker.twitter" target="_blank">
                                <i class="fab fa-twitter" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="text-center mt-9">
                        <button type="button" class="btn btn-wide btn-primary transition-3d-hover" data-dismiss="modal" aria-label="Close">Back to Speaker List</button>
                      </div>
                    </div>
                    <!-- End Body -->
                  </div>
                </div>
                <!-- End Modal -->
              </div>
            </li>
          <!-- End Speaker -->
          </ol>
        </span>
      </div>
    </div>
    <!-- ========== End Speakers Section ========== -->

    <!-- ========== Agenda Section ========== -->
    <div class="container space-2">
      <!-- Title -->
      <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">Schedule Details</span>
        <h2>Apidays Live Hong Kong 2021 Agenda</h2>
      </div>
      <!-- End Title -->

      <div id="apidays2021agenda" class="row">
        <div class="col-lg-6 mb-6 mb-lg-0">
          <a
            class="js-fancybox media-viewer"
            href="javascript:;"
            data-hs-fancybox-options="{
              &quot;selector&quot;: &quot;#apidays2021agenda .js-fancybox&quot;,
              &quot;speed&quot;: 700
            }"
            :data-src="require('@/assets/img/events/apidays-live-hongkong/2021/images/apidays-live-hongkong-2021-agenda-825.png')"
            data-caption="Apidays Live Hong Kong 2021 Agenda – Day 1 (August 25, 2021)"
          >
            <img class="img-fluid rounded shadow" src="@/assets/img/events/apidays-live-hongkong/2021/images/apidays-live-hongkong-2021-agenda-825.png" alt="Apidays Live Hong Kong 2021 Agenda – Day 1 (August 25, 2021)">
          </a>
        </div>
        <div class="col-lg-6 mb-0">
          <a
            class="js-fancybox media-viewer"
            href="javascript:;"
            data-hs-fancybox-options="{
              &quot;selector&quot;: &quot;#apidays2021agenda .js-fancybox&quot;,
              &quot;speed&quot;: 700
            }"
            :data-src="require('@/assets/img/events/apidays-live-hongkong/2021/images/apidays-live-hongkong-2021-agenda-826.png')"
            data-caption="Apidays Live Hong Kong 2021 Agenda – Day 2 (August 26, 2021)"
          >
            <img class="img-fluid rounded shadow" src="@/assets/img/events/apidays-live-hongkong/2021/images/apidays-live-hongkong-2021-agenda-826.png" alt="Apidays Live Hong Kong 2021 Agenda – Day 2 (August 26, 2021)">
          </a>
        </div>
      </div>
    </div>
    <!-- ========== End Agenda Section ========== -->

    <!-- ========== Sponsors Section ========== -->
    <div class="bg-light">
      <div class="container space-top-2 space-bottom-1">
        <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-9">
          <span class="d-block small font-weight-bold text-cap mb-2">Selected Sponsors</span>
          <h2>Apidays Live Hong Kong 2021 is Sponsored by</h2>
        </div>

        <div v-for="list in sponsor_list" :key="list.type" class="mb-9">
          <div class="text-center">
            <span class="divider mb-2 lead font-weight-bold">{{ list.type }}</span>
          </div>
          <div class="row text-center align-items-center" :class="list.css_class">
            <div v-for="sponsor in list.sponsor" :key="sponsor.image_url" class="col-12" :class="sponsor.css_class">
              <img class="img-fluid" :class="sponsor.image_css" :src="require('@/assets/img/events/apidays-live-hongkong/2021/sponsors/' + sponsor.image_url)">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End sponsors Section ========== -->

    <!-- ========== 2020 Section ========== -->
    <!-- <div class="bg-img-hero-center" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="container space-2 space-lg-3">
        <div class="row justify-content-lg-between align-items-lg-center">
          <div class="col-lg-6 order-lg-2" data-aos="fade-left">
            <img class="img-fluid" src="@/assets/img/events/apidays-live-hongkong/images/apidays-2020-facts.png">
          </div>
          <div class="col-lg-6 order-lg-1">
            <div class="pt-8 pt-lg-0">
              <div class="mb-5">
                <span class="d-block small font-weight-bold text-cap mb-2">What happened in 2020</span>
                <h2 class="mb-3">A Look Back at 2020 Conference</h2>
                <p>Thanks for all your support to Apidays Live Hong Kong 2020. Please share your feedback to access 2020 on-demand video highlights and hope we will see you again in Apidays Live Hong Kong 2021!</p>
              </div>
              <span class="d-block text-center">
                <a class="btn btn-primary mr-1 mr-sm-4 mb-2 transition-3d-hover" href="https://bit.ly/apidaysHK2020-PostEvent" target="_blank">
                  Share Feedback
                </a>
                <a class="btn btn-outline-primary mb-2 transition-3d-hover" href="/events/apidays-live-hongkong">
                  2020 Event Details
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ========== End 2020 Section ========== -->

    <!-- ========== 2021 Section ========== -->
    <!-- <div class="container space-2 space-lg-3">
      <div class="row justify-content-lg-between align-items-lg-center">
        <div class="col-lg-6" data-aos="fade-right">
          <div class="w-60 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/community-apply.svg" alt="SVG">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="pt-8 pt-lg-0">
            <div class="mb-5">
              <span class="d-block small font-weight-bold text-cap mb-2">coming up next</span>
              <h2 class="mb-3">Apidays Live Hong Kong 2021</h2>
              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>Apidays Live Hong Kong 2021 will be live on <span class="font-weight-bold">August 25 & 26, 2021</span>.</p>
                </div>
              </div>
              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>We are currently calling for speakers, sponsors and community partners for the event. Talk to us for more information if you are interested in being a part of Apidays Live Hong Kong 2021!</p>
                </div>
              </div>
            </div>
            <span class="d-block text-center">
              <a class="btn btn-primary mr-1 mr-sm-4 mb-2 transition-3d-hover" href="https://bit.ly/apidayshk2021-registration" target="_blank">
                Register Now
              </a>
              <a class="btn btn-outline-primary mb-2 transition-3d-hover" href="javascript:;" data-toggle="modal" data-target="#providerForm">
                Apply to Speak or Sponsor
              </a>
            </span>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ========== End 2021 Section ========== -->

    <!-- ========== Gallery Section ========== -->
    <div class="space-2">
      <div class="position-relative">
        <div class="container space-1 position-relative z-index-2 ">
          <div class="w-md-80 w-lg-80 text-center mx-md-auto mb-9">
            <span class="d-block small font-weight-bold text-cap mb-2">Gallery</span>
            <h2>The Best Moments of Apidays Live Hong Kong 2021</h2>
          </div>
          <div id="fancyboxGallery1" class="row">
            <div v-for="item in gallery_list" :key="item.image_url" class="col-12 px-2 transition-3d-hover" :class="item.class">
              <a
                class="js-fancybox media-viewer"
                href="javascript:;"
                data-hs-fancybox-options="{
                            &quot;selector&quot;: &quot;#fancyboxGallery1 .js-fancybox&quot;,
                            &quot;speed&quot;: 700,
                            &quot;loop&quot;: true
                        }"
                :data-src="require('@/assets/img/events/apidays-live-hongkong/2021/images/' + item.image_url)"
                data-caption="Apidays Live Hong Kong 2021"
              >
                <div class="img-fluid rounded h-250rem bg-img-hero shadow-soft" :style="{'background-image': 'url(' + require('@/assets/img/events/apidays-live-hongkong/2021/images/' + item.image_url) + ')'}" data-aos="fade-up" :data-aos-delay="item.data_aos_delay" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Gallery Section ========== -->

    <!-- ========== Subscription Section ========== -->
    <!-- <div class="container-fluid space-2 space-top-lg-3 space-bottom-lg-2 gradient-y-sm-primary">
      <div class="row justify-content-sm-center align-items-lg-center text-center">
        <div class="col-lg-3 d-none d-lg-block" />
        <div class="col-sm-8 col-lg-6">
          <div class="mb-5">
            <h2>Interested in Apidays Live Hong Kong 2021 Event Highlights?</h2>
            <div class="w-lg-80 mx-auto">
              <p>Subscribe to our newsletter and receive exclusive replay videos &amp; presentation slides of the conference!</p>
            </div>
          </div>
          <div class="w-md-75 w-lg-80 mx-auto form-subscribe">
            <form ref="myform" />
          </div>
        </div>
        <div class="col-lg-3 d-none d-lg-block" data-aos="fade-left">
          <div class="max-w-33rem w-100 transform-rotate-2 ml-auto">
            <div class="device device-iphone-x">
              <img class="device-iphone-x-frame" src="@/assets/svg/illustrations/subscribe-mobile.svg">
            </div>

            <figure class="max-w-19rem w-100 position-absolute top-0 left-0 z-index-n1 mt-n5 ml-n5">
              <img class="img-fluid" src="@/assets/svg/components/dots-2.svg">
            </figure>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ========== End Subscription Section ========== -->

    <!-- ========== Popup Modals ========== -->
    <div id="oahResourcesModal" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="oahResourcesModalTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="oahResourcesModal" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="w-md-90 mx-auto mb-6 form-submit">
              <div class="text-center">
                <img class="img-fluid max-w-23rem mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
                <p>This resource is now available on <strong>OpenAPIHub</strong>—our one-stop API Community Platform.<br>Please log in to the platform, or register as an OpenAPIHub member for FREE to watch the resource now!</p>
                <a id="ga-BW-CC15-01-03" href="https://hub.openapihub.com/member/community-updates/20210825-Apidays-Live-Hong-Kong-2021/resources" target="_blank" class="btn btn-primary transition-3d-hover">Watch on OpenAPIHub</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div id="recapSubscribeForm" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="recapSubscribeFormTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="recapSubscribeForm" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="w-md-90 mx-auto mb-6 form-submit">
              <div class="text-center">
                <h4>Subscribe to our newsletter and watch playback videos NOW!</h4>
                <img class="img-fluid max-w-23rem mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
              </div>
              <form ref="recapSubscribeForm" />
              <div v-if="isSubmitted" class="modal-submitted-content">
                <div class="w-lg-80 mx-auto text-center">
                  <hr>
                  <h5 class="my-5">You may find the playback videos and discover other resources of Apidays Live Hong Kong 2021 below:</h5>
                  <router-link class="card card-frame p-3" :to="{ path: '/events/apidays-live-hongkong-2021/recap' }">
                    <div class="d-flex align-items-center">
                      <figure class="max-w-6rem w-100">
                        <img class="img-fluid" src="@/assets/svg/icons/icon-41.svg">
                      </figure>
                      <div class="pl-3 font-weight-bold">Watch Apidays 2021 Recap NOW!</div>
                      <div class="ml-auto"><i class="fas fa-chevron-right" /></div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ========== End Popup Modals ========== -->
  </main>
</template>

<script>
const $ = require('jquery')
import OrganiserBar from '@/components/organiser-bar'
import HSGoTo from '../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import HSVideoPlayer from '../../../assets/vendor/hs-video-player/src/js/hs-video-player'
import '../../../assets/js/hs.core.js'
import '../../../assets/js/hs.fancybox.js'
import '../../../assets/vendor/fancybox/dist/jquery.fancybox.js'
import 'bootstrap'

export default {
  name: 'Apidays2021',
  components: {
    OrganiserBar
  },
  data() {
    return {
      isSubmitted: false,
      gallery_list: [
        {
          image_url: 'apidays-2021-snapshot-2.jpg',
          class: 'col-md-4 mb-3',
          data_aos_delay: 0
        },
        {
          image_url: 'apidays-2021-snapshot-1.jpg',
          class: 'col-md-3 mb-3',
          data_aos_delay: 100
        },
        {
          image_url: 'apidays-2021-snapshot-3.jpg',
          class: 'col-md-5',
          data_aos_delay: 200
        },
        {
          image_url: 'apidays-2021-snapshot-4.jpg',
          class: 'col-md-5 mb-3 mb-md-0',
          data_aos_delay: 100
        },
        {
          image_url: 'apidays-2021-snapshot-5.jpg',
          class: 'col-md-3 mb-3 mb-md-0',
          data_aos_delay: 200
        },
        {
          image_url: 'apidays-2021-snapshot-6.jpg',
          class: 'col-md-4',
          data_aos_delay: 300
        }
      ],
      organiser_list: {
        title: 'Apidays Live Hong Kong 2021',
        image_path: 'apidays-live-hongkong',
        css: 'space-lg-3 space-2',
        organiser: [
          {
            post: 'Global Partner',
            image_url: 'apidays.png',
            data_aos_delay: 0,
            css: ''
          },
          {
            post: 'Lead Organizer',
            image_url: 'benovelty.png',
            data_aos_delay: 150,
            css: 'column-divider-md column-divider-20deg px-md-3'
          }
        ]
      },
      selected_group: 'Main Track',
      speaker_list: [
        {
          group: 'Main Track',
          title: 'Main Track',
          speaker: [
            {
              data_target: 'ajayModal',
              image_url: 'ajay-pro.jpg',
              name: 'Ajay Biyani',
              job_title: 'Regional Vice President, ASEAN of ForgeRock',
              company: 'ForgeRock',
              position: 'Regional Vice President, ASEAN',
              media_card: 'ajay-smc.jpg',
              about: [
                'Ajay is a strategist, innovator, and communicator of digital identity, access, security, and privacy, with a particular focus on creating successful wide-scale ecosystems and fostering individual empowerment. He drives Identity Relationship Management adoption for the ForgeRock Open Identity Stack in APAC & Japan.',
                'He specialises in advising clients on emerging identity and security solutions, distributed authorisation, privacy enhancement, and API security solutions and their impact on the Web and the Internet of Things. He has been invited as a guest speaker in major security events and online security webinars.'
              ],
              topic_title: 'Digital Identity Centric Approach to Accelerate HKMA OpenAPI Phase3/4 Compliance',
              linkedin: 'ajaybiyani'
            },
            {
              data_target: 'alancModal',
              image_url: 'alanc-pro.jpg',
              name: 'Alan Chatt',
              job_title: 'Offering Manager at IBM',
              company: 'IBM',
              position: 'Offering Manager',
              media_card: 'alanc-smc.jpg',
              linkedin: 'alanchatt'
            },
            {
              data_target: 'alangModal',
              image_url: 'alang-pro.jpg',
              name: 'Alan Glickenhouse',
              job_title: 'Digital Transformation Business Strategist at IBM',
              company: 'IBM',
              position: 'Digital Transformation Business Strategist',
              media_card: 'alang-smc.jpg',
              topic_title: 'APIs - How did we get here and where are we going next? ',
              topic_description: [
                'Old integration patterns never die; we just get new ones!  This session starts by looking backward at the integration challenges that led to the development of APIs.  But, believing that we have completely solved all challenges in this area and there is nothing else that needs to be done is clearly wrong.  So, what are the next challenges that need to be addressed and how will we address them?  Come to this session to find out.'
              ],
              linkedin: 'alan-glickenhouse-2b065a3'
            },
            {
              data_target: 'andrewModal',
              image_url: 'andrew-pro.jpg',
              name: 'Andrew Dent',
              job_title: 'Director of Solution Engineering at Mulesoft',
              company: 'Mulesoft',
              position: 'Director of Solution Engineering',
              topic_title: 'Composable data for the composable enterprise - Liquefying data capital with APIs',
              topic_description: [
                'This talk explores the economic value of APIs, the importance of data liquidity--an organization\'s ability to serve up the right data in the right context at the right time--in maximizing that value, and the vital role that APIs play in the modern data ecosystem. Attendees will learn practical techniques to promote data liquidity while protecting against data leakage.'
              ],
              linkedin: 'angedent'
            },
            {
              data_target: 'archanaModal',
              image_url: 'archana-pro.jpg',
              name: 'Archana Patel',
              job_title: 'Customer Success Architect at MuleSoft',
              company: 'MuleSoft',
              position: 'Customer Success Architect',
              media_card: 'archana-smc.jpg',
              about: [
                'MuleSoft dev/architect. Helping customers get the most out of the Anypoint platform.',
                'Passionate about encouraging more women to consider tech careers.',
                'Talk to me about API development, integration architecture, DevOps, security and being a woman in tech.'
              ],
              topic_title: 'API Mocking best practices',
              topic_description: [
                'Learn about why mocking is useful when testing APIs and what are the mocking tools available, some do\'s and don\'ts on mocking'
              ],
              linkedin: 'archana-patel'
            },
            {
              data_target: 'asankaModal',
              image_url: 'asanka-pro.jpg',
              name: 'Asanka Abeysinghe',
              job_title: 'Chief Technology Evangelist at WSO2, Inc',
              company: 'WSO2, Inc',
              position: 'Chief Technology Evangelist',
              media_card: 'asanka-smc.jpg',
              about: [
                'Asanka’s goal is to connect humans and technology by helping organizations implement digital transformation programs that result in consumer-driven digital applications. In his current role, Asanka drives efforts to create, refine, and enhance WSO2’s corporate reference architecture and is responsible for spearheading a thought leadership outlook that defines WSO2’s corporate reference methodology for development, customer success, and implementation. Working closely with customers, partners, and analysts, he evangelizes WSO2’s technology vision. Asanka has over 20 years of industry experience, which includes designing and implementing highly scalable distributed systems, SOA and microservice architectures in the financial domain, mobile platforms, and various business integration solutions. He is also a committer of the Apache Software Foundation. Asanka is a regular speaker at numerous global events, and many tech meetups in San Francisco Bay Area.'
              ],
              topic_title: 'Building an Integrated Supply Chain for APIs',
              topic_description: [
                'APIs are the products of the 21st century. As we build out API systems, we find that we are constantly learning from product journeys. We propose a new kind of supply chain - the Integrated Supply Chain for APIs (ISCA) - which is needed by any organization looking to create and monetize API products, either directly or indirectly. In this session, Asanka will outline our vision of the ISCA, identify five key patterns for success, and give a blueprint for creating a digital business based on API products.'
              ],
              linkedin: 'asankaabeysinghe',
              twitter: 'asankama'
            },
            {
              data_target: 'carlModal',
              image_url: 'carl-pro.jpg',
              name: 'Carl Seto',
              job_title: 'Director, Product and Marketing at JETCO',
              company: 'JETCO',
              position: 'Director, Product and Marketing',
              media_card: 'carl-smc.jpg',
              topic_title: 'API Ecosystem and Banking Open API Phase III & IV',
              topic_description: [
                'We would like to share how JETCO APIX develop the API ecosystem and the potential opportunities of Banking Open API Phase III & IV.'
              ]
            },
            {
              data_target: 'claireModal',
              image_url: 'claire-pro.jpg',
              name: 'Claire Barrett',
              job_title: 'Director at APIsFirst',
              company: 'APIsFirst',
              position: 'Director',
              media_card: 'claire-smc.jpg',
              about: [
                'I make strategy happen: a translator of goals and aspirations into technology change that sticks. Galvanising teams and individuals around the big picture; getting traction with short-term priorities for customers and stakeholders; while making tangible progress towards longer-term transformational outcomes.',
                'My best work is at the busy intersection of transformation, scaled agile delivery, systems and platforms overhaul, business architecture, digital innovation, and cultural change. Comfortable with high degrees of ambiguity, I\'m about getting cut through quickly and forging buy-in to ways forward at the coalface of delivery; while maintaining a deep respect for people as humans and recognising the impact of decisions and action on impacted individuals and groups. I’m a systems thinker with wide-ranging experience leading technology, project and consulting teams in industries such as financial services and acute healthcare, across Australia, the UK and Hong Kong.',
                'I believe diversity of thought and input is fundamental to challenging cultural norms and ingrained behaviours constructively. Collaborative and pragmatic in my approach, results come through planning and execution discipline, infectious energy and care for the individual.',
                'As an active champion for women in technology and global lead of Women In APIs, I am regularly sought out to mentor and coach women at different stages in their lives and careers. I have conceived and delivered multiple programs that have made a tangible difference for balance and inclusion targeted at groups such as female high school students looking at university and job choices; women returning to IT work after a career break; and non-native English speakers gaining confidence with better writing and communication skills.'
              ],
              topic_title: 'Getting API Management adopted: the hearts and minds beyond the technology',
              linkedin: 'claire-global'
            },
            {
              data_target: 'cliveModal',
              image_url: 'clive-pro.jpg',
              name: 'Clive Chan',
              job_title: 'Principal Solution Architect at WeBank',
              company: 'WeBank',
              position: 'Principal Solution Architect',
              media_card: 'clive-smc.jpg',
              topic_title: 'How to use federated learning to achieve secure, privacy-preserving joint AI model building in production',
              topic_description: [
                'Clive will explain the concept and application of federated learning in this session, how federated learning provides a secure computing framework based on data privacy protection and supports the secure computation of various algorithms, including machine learning and deep learning. In addition, Clive will share how to use Federated Learning Solution in production use cases.'
              ],
              linkedin: 'clive-chan-hk'
            },
            {
              data_target: 'damirModal',
              image_url: 'damir-pro.jpg',
              name: 'Damir Svrtan',
              job_title: 'Senior Software Engineer at Netflix',
              company: 'Netflix',
              position: 'Senior Software Engineer',
              media_card: 'damir-smc.jpg',
              about: [],
              about_special: '<span class="d-block mb-3">Senior Software Engineer at Netflix. Conference speaker, technical writer, open source lover and contributor. Formerly organized Ruby Zagreb meetups. Check out some of the talks I gave or some of the blog posts I wrote: <a href="https://damir.svrtan.me/" target="_blank">https://damir.svrtan.me/</a></span>',
              topic_title: 'Less Data is More',
              topic_description: [
                'Often developers design APIs that expose more than is needed - unnecessary fields, redundant relationships, and endpoints that no one asked for. These kinds of practices later on introduce communication overhead, extra maintenance costs, negative performance impacts, and waste time that could have been spent better otherwise. We\'ll posll walk through how to design minimal APIs that are straightforward to use, and that are exactly what our consumers need!'
              ],
              linkedin: 'damir-svrtan-1120b085'
            },
            {
              data_target: 'davidModal',
              image_url: 'david-pro.jpg',
              name: 'David O\'Neill',
              job_title: 'CEO of APImetrics Inc',
              company: 'APImetrics Inc',
              position: 'CEO',
              media_card: 'david-smc.jpg',
              about: [
                'David has worked in mobile and cloud computing solutions since their inception, building API gateway products and more recently bringing AI and ML to API and Microservice performance analysis with APImetrics.'
              ],
              topic_title: 'Building Trust Frameworks in Open Banking APIs',
              topic_description: [
                'One of the challenges in many of the growing number of regulated API sectors is agreeing standards of measurement between providers, users and regulators. How do you agree performance when, in reality, both sides in the API contract can ‘prove’ the other is lying.',
                'This talk will focus on some of the challenges seen in regulated API environments such as those in the UK with OBIE, Europe with PSD, Australia with CDR and the growing likelihood of regulation from the CBR in the US.',
                'Finally we will explore some of the ways you can build out that trust framework and what work remains to be done in terms of security, business standards and so on.'
              ],
              linkedin: 'davidon',
              twitter: 'daveon'
            },
            {
              data_target: 'derekModal',
              image_url: 'derek-pro.jpg',
              name: 'Derek So',
              job_title: 'APJ Principal Technologist - Cloud Native at SUSE',
              company: 'SUSE',
              position: 'APJ Principal Technologist - Cloud Native',
              media_card: 'derek-smc.jpg',
              topic_title: 'Building a solid interoperable foundation for your Enterprise APIs with SUSE Rancher and Kubernetes',
              topic_description: [
                'APIs have become both engines of innovation and the source of competitive differentiation. Incorporating APIs as part of your overall IT strategy can help companies deliver seamless experiences for developers, customers, and partners to win in the digital economy. Adopting the right set of technologies is instrumental to the success of an enterprise’s API strategy. A variety of technologies are available to cater to the various aspects of the API lifecycle from development to management to deployment to consumption etc. A common ingredient that underpins all these stages is the foundational platform. In this session we will discuss the potential hurdles of running and managing APIs in a hybrid and multi-cloud environment and how companies can build a resilient, scalable and interoperable foundation for their APIs with the SUSE Rancher and Kubernetes solution.'
              ],
              linkedin: 'derek-so-27184824'
            },
            {
              data_target: 'divModal',
              image_url: 'div-pro.jpg',
              name: 'Div Manickam',
              job_title: 'Mentor and Facilitator of Women in APIs',
              company: 'Women in APIs',
              position: 'Mentor and Facilitator',
              media_card: 'div-smc.jpg',
              about: [
                'Div Manickam is passionate to empower a mindful team and foster a vulnerable culture where everyone feels their voice is heard and can truly be themselves in a safe environment.',
                'She has led product marketing efforts in startups and Fortune 500 technology companies.'
              ],
              about_special: '<span class="d-block mb-3">Div was recognized as one of the <a href="https://productmarketingalliance.com/top-50-product-marketing-influencers/"  target="_blank">top 50 Product Marketing Influencers in 2019 and 2020</a> and <a href="https://productmarketingalliance.com/product-marketing-awards-2020-the-winners/"  target="_blank">Positioning Maestro of the Year 2020</a> by Product Marketing Alliance for redefining standards, helping shape the evolution of PMM, and elevating awareness among the C-suite.</span><span class="d-block mb-3">Div enjoys sharing her experiences on leadership and product marketing on <a href="https://divmanickam.medium.com/" target="_blank">Medium</a>.</span><span class="d-block"><a href="https://divmanickam.medium.com/" target="_blank">https://divmanickam.medium.com/</a></span><span class="d-block mb-3"><a href="https://linkedIn.com/in/divmanickam" target="_blank">https://linkedIn.com/in/divmanickam</a></span>',
              topic_title: 'API narrative: A true story of APIs and I',
              topic_description: [
                'Imagine a world where Business and IT are speaking the same language of APIs with shared OKRs of business influence and impact. Everyday, we wake up to be inspired by the power of APIs. What if we can tell the world the same narrative from API design to API lifecycle management.',
                'Join me in the session to hear my story of how I joined the API movement as the Product Marketing Manager for API Management, Boomi in 2016 and in 2020 advocating for Women in APIs to bring diversity, inclusion and belonging around the world.'
              ],
              linkedin: 'divmanickam',
              twitter: 'divmanickam'
            },
            {
              data_target: 'toaModal',
              image_url: 'toa-pro.jpg',
              name: 'Dr. Toa Charm',
              job_title: 'Associate Professor of Practice in Management at CUHK Business School',
              company: 'CUHK Business School',
              position: 'Associate Professor of Practice in Management',
              media_card: 'toa-smc.jpg',
              about: [],
              about_special: '<ul class="mb-3"><li>Associate Professor of Practice in Management, CUHK Business School</li><li>Chairman and Principal Advisor of a Few Elite Tech Start-ups in Asia</li><li>Former Senior Management: Cyberport, HSBC, IBM, Oracle and Kingdee</li></ul><span class="d-block mb-3">Dr. Toa Charm has over 30 years of experience in the innovation and technology industry and has served as a senior executive at Cyberport, IBM, Oracle, HSBC and Kingdee. His expertise is in big data, artificial intelligence, fintech, e-commerce, start-up acceleration, and digital transformation of enterprises. Dr, Charm is the former Chief Public Mission Officer (CPMO) at the Hong Kong Cyberport. He established and nurtured a world-leading innovation and technology ecosystem with more than 1,200 start-ups from more than 30 countries, three of them have become multinational unicorns.</span><span class="d-block mb-3">Dr. Charm is an associate professor of practice in management at the business school of the Chinese University of Hong Kong. He is also the Chairman & Principal Advisor of several Asia’s FinTech, AI, Big Data, eCommerce and ESG start-ups. Dr. Charm is active in the innovation and technology ecosystem in Asia. He has been invited as an advisor to several Asian companies, government departments, universities, societies, and chambers of commerce including Cyberport, HKSTP, ASTRI, CityU 「HK Tech 300」, Ping An Cloud Accelerator, Hong Kong Consumer Council, and so on. Dr. Charm has been the Chief Judge (FinTech) of the most reputable Hong Kong ICT Awards from 2019 to 2021 as well as judges for many international and local tech competitions. He published his book <數碼力大提升> in 2020. Dr. Charm received the College of Business Distinguished Alumni Award in 2019 and the Outstanding Doctor of Business Administration Alumni Award in 2018 from the City University of Hong Kong.</span>',
              topic_title: 'Unleash the Power of Big Data with API Collaboration',
              topic_description: [
                'Data is not just the most important asset in the digital economy but also our customer\'s voice. Unfortunately, not many companies can successfully unleash the power of data to create a delightful customer experience. One of the key reasons is the lack of data. Collaboration among enterprises to exchange data was difficult due to lack of trust, partnership model, and technological challenges. The uprising adoption of APIs in enterprises can potentially resolve these issues. Companies, such as HSBC, DBS, HKTVMall, Klook, etc., have launched their API strategies to serve their customers better through API collaboration. In this session, Dr. Charm will talk about how enterprises can unleash the power of big data and innovation to create a delightful customer journey with API collaboration.'
              ],
              linkedin: 'toacharm'
            },
            {
              data_target: 'easonModal',
              image_url: 'eason-pro.jpg',
              name: 'Eason Lai',
              job_title: 'Lead Digital Solution Architect at AIA Hong Kong and Macau',
              company: 'AIA Hong Kong and Macau',
              position: 'Lead Digital Solution Architect',
              media_card: 'eason-smc.jpg',
              about: [
                'A seasoned Lead Digital Solution Architect in AIA Hong Kong, Digitalization & Innovation Team with 20 years of experience on infrastructure, application development, data platform and latest cloud technologies (PaaS, IaaS, Hybrid Cloud, Big Data/Machine Learning/AI, IoT). Working experience are including multinational technology giant company, Microsoft & IBM.'
              ],
              topic_title: 'Building AI/ML model API for Digital App',
              topic_description: [
                'In this session, I will share about use case scenarios and how do we build AI / Machine Learning model API to serve for Digital Apps.'
              ],
              linkedin: 'eason-lai-8618a235',
              twitter: 'easonlaiwork'
            },
            {
              data_target: 'emmelynModal',
              image_url: 'emmelyn-pro.jpg',
              name: 'Emmelyn Wang',
              job_title: 'Global Business Development Lead, AWS Marketplace at Amazon',
              company: 'Amazon',
              position: 'Global Business Development Lead, AWS Marketplace',
              media_card: 'emmelyn-smc.jpg',
              about: [
                'Emmelyn Wang has 20 years of experience driving business across many industries including eCommerce and global distribution as a software and technology innovation leader. She focuses on DevOps and the role of API management, integration, and low/no code products and platform programs and creating profitable ecosystems through meaningful integrations. Recently, she served and led efforts as part of Axway’s Office of the CTIO. In her new role, she is leading a category business on AWS Marketplace to make it easier for customers to find, try and buy, and deploy software.',
                'She is an internationally recognized speaker and thought leader for the business of APIs and the platforms and ecosystems they serve. Emmelyn\'s background also includes developer community engagement, working on API product teams building APIs for internal and external stakeholders, and supporting partners integrating with APIs. She has also led technical marketing teams creating engineering-focused content to innovate in both software and hardware supporting global companies to break revenue and adoption records.',
                'She started her career in Austin, Silicon Hills (Texas) where she also contributed to the growth of several startups. Emmelyn served on the core team of the AsyncAPI specification, sister spec to the OAS spec. She holds a Masters in Technical Communication. She contributed and spoke at GDS Insight, CIO Leadership, API Days, API the Docs, Nordic APIs Annual Summit, Write the Docs, Lavacon, Women Who Code, and other digital business events worldwide. She runs the APIOps Meet up and is based in Dallas, TX.'
              ],
              topic_title: 'Go to Market with APIs',
              topic_description: [
                'All of the ways to monetize your API-led strategy and business models (which I\'m submitting articles for on apiscene) that relate to the APIs and model customers experience through the AWS Marketplace: Specifically focusing on full API life cycle management (objective, vendor neutral third party view), integration iPaaS, and low and no code I will have specific Customer stories and how developers saved time and were able to do more with what they have, faster. For each apidays region, I will have specific local stories relevant to the topics the local organizers and I have collaborated on. For example, Marjukka reached out about APIOps for Finland & North and Paul reached out for Australia.'
              ],
              linkedin: 'emmelyn',
              twitter: 'lifewingmate'
            },
            {
              data_target: 'ganModal',
              image_url: 'gan-pro.jpg',
              name: 'Gan Yong',
              job_title: 'CIO of Consumer Banking at China Construction Bank (Asia)',
              company: 'China Construction Bank (Asia)',
              position: 'CIO of Consumer Banking',
              topic_title: '(Mandarin Session) Bank-as-a-Service (BAAS): Empowering business by Open API'
            },
            {
              data_target: 'geoffreyModal',
              image_url: 'geoffrey-pro.jpg',
              name: 'Geoffrey Lau',
              job_title: 'Chief Solution Architect at Software AG Asia Pacific & Japan',
              company: 'Software AG Asia Pacific & Japan',
              position: 'Chief Solution Architect',
              media_card: 'geoffrey-smc.jpg',
              about: [
                'Geoffrey has extensive experience in working with clients applying API & integration related innovations to extend digital capabilities and competitiveness, from conceptual ideation to realization.  He assumes the roles of advisory and technology guidance for clients across a variety of industry sectors in Asia region and also in Hong Kong.  He currently contributes to the IT community in capacity of regional chief solution architect for Software AG North Asia & Greater China.'
              ],
              topic_title: 'Be the Orchestrator for Banking-as-a-Service (BaaS): A Conceptual Solution Framework for Re-bundling of Financial Services API Products',
              topic_description: [
                'Looking beyond just OpenBanking and the HKMA Open API initiatives aiming at un-bundling financial services, the next success of financial services related players lies in the ability to quickly create new product re-bundles by orchestrating services in ecosystems and maximizing the value captures from the increasingly distinctive customer needs.  It is a race to become the winning orchestrator in ecosystems.  In this session, we look at a conceptual solution framework important to the success of the orchestrator, the technology elements it requires and how the future development should look like.'
              ],
              linkedin: 'geoffrey-lau-1b399a6a'
            },
            {
              data_target: 'hieuModal',
              image_url: 'hieu-pro.jpg',
              name: 'Hieu Nguyen Nhu',
              job_title: 'Cloud Native Senior Technical Specialist at Microsoft',
              company: 'Microsoft',
              position: 'Cloud Native Senior Technical Specialist',
              media_card: 'hieu-smc.jpg',
              topic_title: 'Enterprise Integration Patterns for OpenAPI Initiatives',
              topic_description: [
                'I will talk about the common pattern that Azure can help to orchestrate calls to various enterprise systems across clouds and on premises. In this session, you will learn about how we use this pattern to support OpenAPI initiative in key industries.'
              ],
              linkedin: 'nguyennhuhieu'
            },
            {
              data_target: 'howardModal',
              image_url: 'howard-pro.jpg',
              name: 'Howard Wong',
              job_title: 'Chief FinTech Evangelist of FORMS HK',
              company: 'FORMS HK',
              position: 'Chief FinTech Evangelist',
              media_card: 'howard-smc.jpg',
              about: [
                'Currently CFTE of FORMS Syntron Information (HK) Limited, under SZ FORMS Syntron Information Co. Ltd ("FORMS" in short) which is listed in SZ with stock code 300468. FORMS is now the leading digital technology advisor and enabler to help virtual banks to build the bank, to help established banks to change the bank, and to help central banks to shape the future of money via central bank digital currency. Before joining FORMS HK, Howard was in charge of HSBC HK’s Retail Banking and Wealth Management Customer Due Diligence Office. More than 20 years of solid change management and banking experience. Howard started his career as a bank programmer. Then progressed to senior IT positions and led teams in HK and Guangzhou before becoming a Technology & Services Management Trainee in HSBC. Howard worked with C-suite executives across the globe and drove impactful transformation programmes such as service digitalization, AML & CFT, organization restructure, business intelligence and process revamp. Howard is also a serial entrepreneur and a start-up enthusiast. His businesses cover online games, on-demand apparel, digital imaging and more. Howard is a member of the business honor society Beta Gamma Sigma, and a member of Mensa HK.'
              ],
              topic_title: 'Leveraging open banking phase 3 & 4 and CDI to create a smart and vibrant SME ecosystem',
              topic_description: [
                'As we are getting into a new stage of data driven economy, let\'s take a peep into some use cases that can bring win-win to SMEs, financial institutions and fintechs, at the same time creating a smarter and more vibrant SME ecosystem that ultimately benefits the society.'
              ],
              linkedin: 'howardchwong'
            },
            {
              data_target: 'hugoModal',
              image_url: 'hugo-pro.jpg',
              name: 'Hugo Guerrero',
              job_title: 'APIs & Messaging Developer Advocate at Red Hat',
              company: 'Red Hat',
              position: 'APIs & Messaging Developer Advocate',
              media_card: 'hugo-smc.jpg',
              about: [
                'Hugo Guerrero works at Red Hat as an APIs and messaging developer advocate. In this role, he helps the marketing team with technical overview and support to create, edit, and curate product content shared with the community through webinars, conferences, and other activities. With more than 20 years of experience as a developer, consultant, architect, and software development manager, he also works on open source software with major private and federal public sector clients in Latin America.'
              ],
              topic_title: 'Event-driven APIs & Schema governance for Apache Kafka',
              topic_description: [
                'With Apache Kafka’s rise for event-driven architectures, developers require a specification to design effective event-driven APIs. AsyncAPI has been developed based on OpenAPI to define the endpoints and schemas of brokers and topics. For Kafka applications, the broker’s design to handle high throughput serialized payloads brings challenges for consumers and producers managing the structure of the message. For this reason, a registry becomes critical to achieve schema governance.',
                'Apicurio Registry is an end-to-end solution to store API definitions and schemas for Kafka applications. The project includes serializers, deserializers, and additional tooling. The registry supports several types of artifacts like OpenAPI, AsyncAPI, GraphQL, Apache Avro, Google protocol buffers, JSON Schema, Kafka Connect schema, WSDL, XML Schema (XSD). It also checks them for validity and compatibility rules.',
                'In this session, we will be covering the following topics:'
              ],
              topic_special: '<ul><li>The importance of having a contract-first approach to event-driven APIs</li><li>What is AsyncAPI, and how it helps to define Kafka endpoints and schemas</li><li>The Kafka challenges on message structure when serializing and deserializing</li><li>Introduction to Apicurio Registry and schema management for Kafka</li><li>Examples of how to use Apicurio Registry with popular Java frameworks like Spring and Quarkus</li></ul>',
              linkedin: 'hugoguerrero',
              twitter: 'hguerreroo'
            },
            {
              data_target: 'isaacModal',
              image_url: 'isaac-pro.jpg',
              name: 'Isaac Wong',
              job_title: 'AI Solution Architect at WeBank',
              company: 'WeBank',
              position: 'AI Solution Architect',
              media_card: 'isaac-smc.jpg',
              about: [
                '20+ years\' experience AI practitioner, seasoned data scientist, passionate instructor and speaker',
                'Generate actionable insights from BI, analytics and big data technologies',
                'Focusing on FSI, retail and gaming with segmentation, prediction model and graph analytics specialization',
                'Driving AML, pricing, marketing, sales management and CRM initiatives for enterprise'
              ],
              topic_title: 'Federate Learning for Banking',
              topic_description: [
                'Sharing on technology trend, business application and use cases of the captioned privacy enhanced computation technique'
              ],
              linkedin: 'dreamer0507'
            },
            {
              data_target: 'jasonModal',
              image_url: 'jason-pro.jpg',
              name: 'Jason Esli',
              job_title: 'Solutions Consultant at New Relic',
              company: 'New Relic',
              position: 'Solutions Consultant',
              media_card: 'jason-smc.jpg',
              topic_title: 'DevOps is Dead',
              linkedin: 'jasonesli'
            },
            {
              data_target: 'jeffreyModal',
              image_url: 'jeffrey-pro.jpg',
              name: 'Jeffrey Lam',
              job_title: 'Senior Solution Engineer, Greater China Region at Confluent',
              company: 'Confluent',
              position: 'Senior Solution Engineer, Greater China Region',
              media_card: 'jeffrey-smc.jpg',
              topic_title: 'Topic 1: Rethinking Financial Services with Data in Motion',
              topic_title_2: 'Topic 2: Transforming the Financial Services Industry with Event Stream Data : Use Cases from APAC Region',
              topic_description_2: [
                'Banks such as Citigroup, HSBC Canara, JP Morgan, National Stock Exchange, DBS, Bank Mandiri and Euronext have partnered with Confluent to identify organizational or revenue producing improvements and develop new products or services with data in motion.',
                'Attend this live RoundTable as we delve deeper into the rise of streaming data ; how it is transforming the financial services industry by providing the critical, timely information needed to :'
              ],
              topic_special_2: '<ul class="mb-3"><li>Deliver world-class customer experience</li><li>Detect and prevent fraud</li><li>Tie together disparate legacy systems</li><li>Power new digital services and sources of revenue</li></ul>',
              linkedin: 'jeffrey-lam-9b65aa1'
            },
            {
              data_target: 'jenksModal',
              image_url: 'jenks-pro.jpg',
              name: 'Jenks Guo',
              job_title: 'Partner Solutions Lead at Linktree',
              company: 'Linktree',
              position: 'Partner Solutions Lead',
              media_card: 'jenks-smc.jpg',
              about: [
                'Jenks is an API fanatic & AI geek. He is passionate about developer relations and building ecosystem for platform companies. He had 3 years experience as a Developer Evangelist at Xero. Currently he leads the Partner Solutions team at Linktree.'
              ],
              topic_title: 'How to start a tech community',
              topic_description: [
                'Technical audiences require very very different ways of engaging. This talk is going to explain tricks and tips on starting up a tech community.'
              ],
              linkedin: 'jenks-guo-58257438'
            },
            {
              data_target: 'jeremyModal',
              image_url: 'jeremy-pro.jpg',
              name: 'Jeremy Glassenberg',
              job_title: 'Product Lead, APIs at Docusign',
              company: 'Docusign',
              position: 'Product Lead, APIs',
              media_card: 'jeremy-smc.jpg',
              about: [],
              about_special: '<span class="d-block mb-3">Jeremy is an experienced Product leader of over 13 years with a proven track record of building and monetizing platforms such as Box and Tradeshift. He has managed and expanded developer platforms to communities of tens of thousands of developers, executed on high-profile integrations promoted by Salesforce and Google, and established key components in paths to monetization. While focused on Product, Jeremy has managed and grown several successful developer communities and Developer Relations teams.</span><span class="d-block mb-3">Jeremy is a mentor to top-tier startup accelerators such as Alchemist, Techstars, and Heavybit, and advises developer-focused startups such as Moesif (API intelligence) and <a href="https://openchannel.io/" target="_blank">OpenChannel.io</a> (framework for app marketplaces).</span><span class="d-block">He is currently working on an API Product initiative at Docusign.</span>',
              topic_title: 'Automating the API Product Lifecycle',
              topic_description: [
                'Creating a successful API requires a proper process from concept and design, through development, and into ongoing maintenance and good developer support. There are many steps to a good API. As developer expectations for better-quality APIs increase, tools have made it easier to do this well. This was only possible with the rise of OpenAPI as a central schema upon which other tools could rely. Looking at the full API Product Lifecycle to design an API people will use, Jeremy Glassenberg will share the newest tools -- and potentially upcoming opportunities -- to better automate the planning and creation of a solid developer program with OpenAPI at the center.'
              ],
              linkedin: 'jglassenberg',
              twitter: 'jglassenberg'
            },
            {
              data_target: 'jesusModal',
              image_url: 'jesus-pro.jpg',
              name: 'Jesus de Diego',
              job_title: 'API Evangelist at Adidas',
              company: 'Adidas',
              position: 'API Evangelist',
              media_card: 'jesus-smc.jpg',
              about: [
                'Software Engineer, I\'ve worked as software engineer and architect with APIs in GFT, Fexco, Fidelity and now, adidas. I\'ve used different architectural layouts and protocols in APIs to form multi-protocol APIs to get the best result from available resources.  The design and implementation of HTTP-based APIs (including gRPC) and Event-Oriented APIs has been key in the tasks of integration with API clients in Cloud, Edge, and Fog. Now, in adidas, I work with a great team to achieve the successful adoption, expansion, and exploitation of the adidas API Ecosystem.'
              ],
              topic_title: 'Multi-Protocol APIs at Scale in adidas',
              topic_description: [
                'We continue to find out the way to build and provide fully automated API Gateway based on APIs that are not restricted by traditional mindsets (one type of API for everything). Times changed and fortunately HTTP protocol has evolved as well! New versions of the HTTP protocol along with Protocol Buffer and gRPC and GraphQL allow us now to do more things in a faster and more efficient way. So, APIs using different styles and protocols are used in Adidas as part of a unified policy regarding integration of data across different systems worldwide. Real-Time processing, streaming of big chunk of data, conditional distribution, consumer load balancing and other amazing features are implemented and used in HTTP-based APIs providing new capabilities and new roles beyond traditional REST-like APIs.',
                'The other side of the question is about the description ad automation of these APIs. A new specification model is needed to provide usage, exploitation and business semantics metadata to APIs so that automated CI/CD pipelines can provide the right configuration to the API Gateway.',
                'The material presented during the session is based on lessons learned the way of building up these APIs where data integrity and stream fault-tolerance is critical.'
              ],
              linkedin: 'jesus-de-diego-61700a5b'
            },
            {
              data_target: 'jimmyModal',
              image_url: 'jimmy-pro.jpg',
              name: 'Jimmy Tsang',
              job_title: 'EASG Committee of Hong Kong Computer Society',
              company: 'Hong Kong Computer Society',
              position: 'EASG Committee',
              media_card: 'jimmy-smc.jpg',
              topic_title: 'The API Trends for 2022 and beyond',
              linkedin: 'jimmy-tsang-ab772b1'
            },
            {
              data_target: 'johnModal',
              image_url: 'john-pro.jpg',
              name: 'John Yeung',
              job_title: 'EASG Committee at Hong Kong Computer Society',
              company: 'Hong Kong Computer Society',
              position: 'EASG Committee',
              media_card: 'john-smc.jpg',
              about: [
                'John Yeung has been working with a range of customers and tackle different challenges using cutting edge technology, and help companies break free from the shackles of legacy technology and develop innovative solutions. Also, he used to be a speaker in public events to share new technology knowledge and experience in areas of Cloud Security and Machine Learning.'
              ],
              topic_title: 'Modernizing Monolith Applications with API Architecture',
              topic_description: [
                'When modernizing applications, it is crucial to understand what functions should be broken out of a monolithic application and how granular you should go with your micro-services. The API layer plays an essential role in this kind of architecture. In this session, we will elaborate how this model works with real-life examples.'
              ]
            },
            {
              data_target: 'jonathanModal',
              image_url: 'jonathan-pro.jpg',
              name: 'Jonathan Cheung',
              job_title: 'Director Open Banking & API at Standard Chartered Bank',
              company: 'Standard Chartered Bank',
              position: 'Director Open Banking & API',
              media_card: 'jonathan-smc.jpg',
              topic_title: 'Open Banking Development from a Regional Perspective',
              linkedin: 'jonathan-cheung-854b91156'
            },
            {
              data_target: 'kimModal',
              image_url: 'kim-pro.jpg',
              name: 'Kim Clark',
              job_title: 'Integration Architect at IBM',
              company: 'IBM',
              position: 'Integration Architect',
              media_card: 'kim-smc.jpg',
              about: [],
              about_special: '<span class="d-block mb-3">Kim is a technical strategist on IBMs integration portfolio working as an architect providing guidance to the offering management team on current trends and challenges. He has spent the last couple of decades working in the field implementing integration and process related solutions. He is one of the primary authors behind much of IBMs agile integration material (<a href="https://www.ibm.com/cloud/integration/agile-integration/"  target="_blank">http://ibm.biz/agile-integration</a>) and he writes and presents regularly on integration architecture and design topics. <a href="https://www.linkedin.com/in/kimjulianclark"  target="_blank">https://www.linkedin.com/in/kimjulianclark</a>',
              topic_title: 'Are events just another type of API? If so, how should we expose them?',
              topic_description: [
                'There is a strong resurgence in interest in event driven architecture. However, RESTful APIs are not always a good model for asynchronous exposure. We will explore the relationship between API management, and event endpoint management, but also the differences. We will also discuss the pros and cons of various protocols and consider what the future might look like for multi-form API management.'
              ],
              linkedin: 'kimjulianclark'
            },
            {
              data_target: 'laurentModal',
              image_url: 'laurent-pro.jpg',
              name: 'Laurent Petroque',
              job_title: 'Director Solutions Engineering at NGINX @ F5',
              company: 'NGINX @ F5',
              position: 'Director Solutions Engineering',
              media_card: 'laurent-smc.jpg',
              about: [
                'Laurent Petroque is the director for solutions engineering for the NGINX APAC BU within the F5 organisation, where he leads a team of technical solutions engineers in supporting customers and partners. Based in Singapore now for the past 3 years, Laurent is originally from France, and has been with F5 for several years.'
              ],
              topic_title: 'Zero Trust security with Service Mesh',
              // topic_description: [
              //   'Improper implementation of microservices increases  the surface area for cyber attacks.  Security controls like  access, resiliency and monitoring need to be handled for each service within a cluster., complicating the overall management process. In this session we examines the tenets of Zero Trust and how a service mesh can be used for microservice environments by creating a transparent infrastructure layer that sits between the containerised application and the network.'
              // ],
              linkedin: 'laurentpetroque'
            },
            {
              data_target: 'marcoModal',
              image_url: 'marco-pro.jpg',
              name: 'Marco Palladino',
              job_title: 'CTO and Co-Founder of Kong Inc.',
              company: 'Kong Inc.',
              position: 'CTO and Co-Founder',
              media_card: 'marco-smc.jpg',
              about: [
                'Marco Palladino is an inventor, software developer and entrepreneur. He is currently the CTO and co-founder of Kong, the leading cloud connectivity company that created widely adopted open source projects such as Kong Gateway, Kuma and Insomnia. Before Kong, Marco co-founded Mashape in 2010, which is today’s largest API marketplace.'
              ],
              topic_title: 'How to Achieve Zero-Trust Security With Kuma Service Mesh',
              topic_description: [
                'Organizations are increasingly becoming more distributed and decoupled so they can deploy more often and improve reliability. However, as we accelerate our digitalization and move to microservices, it is imperative to tighten up our security to prevent intrusions and attacks into our systems. The more moving parts we have, the bigger the attack surface becomes. Zero-trust security is no longer a nice-to-have – it’s a must-have.',
                'In this session, Kong CTO and Co-Founder Marco Palladino will explore what zero-trust security is, why we need it and implementation best practices. He will discuss:'
              ],
              topic_special: '<ul class="mb-3"><li>Best practices for service identity and how platform architects can specify specific rules and attributes to generate an identity on a per-service basis</li><li>How service mesh removes the burden of networking from developers, enabling them to focus more on their core logic</li><li>How to achieve zero-trust security in one click with Kuma service mesh</li></ul>',
              linkedin: 'marcopalladino',
              twitter: 'subnetmarco'
            },
            {
              data_target: 'marjukkaModal',
              image_url: 'marjukka-pro.jpg',
              name: 'Marjukka Niinioja',
              job_title: 'Founding partner of Osaango Ltd',
              company: 'Osaango Ltd',
              position: 'Founding partner',
              media_card: 'marjukka-smc.jpg',
              about: [
                'Niinioja is a co-author of the API Economy 101 book. She is also the creator of the free "Introduction to API Economy" and "Introduction to Data Economy" courses with two universities. Niinioja is the “Mother” of the lean, open, and business-oriented APIOps Cycles method. Niinioja works as an API business consultant, architect, and trainer for companies and public sector. She has 10+ years of experience with API Economy from retail, energy, ICT, construction, Telco, and traffic industries, among others. Her team at Osaango works together with universities, the public sector, and companies.  They consult companies and create courses on how to use APIs, Platforms, and Data in business to grow thriving ecosystems.'
              ],
              topic_title: 'Planning Your Steps to Data Economy Using APIOps Cycles',
              topic_description: [
                'Data Economy is already here, but many companies & public organizations are either embracing it without realizing how it impacts their business and their ecosystem or they are not sure how and what data to monetize. APIs are involved in many ways, but what steps should you take? What does the research say? What are methods you could use? Introducing the open online course Introduction to Data Economy and walking through using the open and Lean APIOps Cycles method.'
              ],
              linkedin: 'marjukkaniinioja',
              twitter: 'MNiinioja'
            },
            {
              data_target: 'mathieuModal',
              image_url: 'mathieu-pro.jpg',
              name: 'Mathieu Pincovai',
              job_title: 'Customer Success Specialist at Amadeus',
              company: 'Amadeus',
              position: 'Customer Success Specialist',
              media_card: 'mathieu-smc.jpg',
              topic_title: 'How to organize a hackathon that really stands out',
              topic_description: [
                'Hackathons are well-known as caffeine-fueled frenetic innovation weekends. But beyond the prizes and the pizza, these events present the perfect opportunity for companies to test and measure the adoption of their APIs.',
                'This talk will share the lessons we at Amadeus learned during the organization of several Hackathons, and show you how to organize your own hackathon from scratch and extract the maximum value from these events.'
              ],
              linkedin: 'mathieu-pincovai-689b829b'
            },
            {
              data_target: 'matthiasModal',
              image_url: 'matthias-pro.jpg',
              name: 'Matthias Biehl',
              job_title: 'API Strategist at Software AG',
              company: 'Software AG',
              position: 'API Strategist',
              media_card: 'matthias-smc.jpg',
              about: [],
              about_special: '<span class="d-block mb-3">Matthias helps clients discover their opportunities for innovation with APIs & ecosystems and turn them into actionable digital strategies. Based on his experience in leading large-scale API initiatives in both business and technology roles, he shares best practices and provides strategic guidance. He has stayed a techie at heart and at some point, got a Ph.D. Matthias publishes a blog at <a href="https://api-university.com/" target="_blank">api-university.com</a>, is the author of several books on APIs, and regularly speaks at technology conferences.</span>',
              topic_title: 'The big rebundling in financial services: threats and opportunities of Banking-as-a-Service (BaaS)',
              topic_description: [
                'While banks are busy figuring out how to best implement and leverage OpenBanking (e.g. HKMA phase III and IV), we will look ahead at what’s next for banks and financial services. Current OpenBanking and API initiatives focus on un-bundling financial services, moving from a package deal of banking services, to smaller, more autonomous, API-enabled Banking-as-a-Service (BaaS) offers. The logical next stage in the evolution of banking products will be a re-bundling of these API products, however, in completely new ways than banks have done in the past. In the next stage, we will likely see financial services deeply embedded in a variety of non-financial products, resulting in the long tail of more customer-centric, tailored, and relevant niche products. If enough niche products for different market niches can form, they stand a good chance to eat significant market share from today\'s mass market banking offers. Today banks mainly compete against other banks just like them, but will increasingly face competition from players outside the financial services industry with different business models, technology stacks and less regulatory constraints. What are the threats and opportunities for the financial services world?'
              ],
              linkedin: 'mbiehl'
            },
            {
              data_target: 'maximebModal',
              image_url: 'maximeb-pro.jpg',
              name: 'Maxime Beugnet',
              job_title: 'Senior Developer Advocate at MongoDB',
              company: 'MongoDB',
              position: 'Senior Developer Advocate',
              media_card: 'maximeb-smc.jpg',
              about: [
                'Maxime has been working with MongoDB for the past 7 years and in the IT industry for 10 years.',
                'He is a MongoDB and Java trainer, with DEV and DBA MongoDB certifications. Maxime joined MongoDB as a Developer Advocate 3 years ago to share his experience with the community. He loves clean code, scuba diving, code challenges and vikings!'
              ],
              topic_title: '20 minutes to build a serverless COVID-19 REST and GraphQL APIs',
              topic_description: [
                'At the beginning of the COVID-19 pandemic, Johns Hopkins University worked on gathering and refining data from multiple sources and provided the world with a Github repository and a bunch of CSV files. The Problem? It’s nearly impossible to build anything from raw data like this.',
                'In this live coding session, MongoDB Developer Advocate Maxime Beugnet will show you how to build a serverless REST and a GraphQL API based on this dataset using Python and the MongoDB Data Platform. We are finally going to put serverless and the MongoDB generous free tier to good use!'
              ],
              linkedin: 'maximebeugnet',
              twitter: 'MBeugnet'
            },
            {
              data_target: 'maximetModal',
              image_url: 'maximet-pro.jpg',
              name: 'Maxime Topolov',
              job_title: 'CEO of GraphQL Portal',
              company: 'GraphQL Portal',
              position: 'CEO',
              media_card: 'maximet-smc.jpg',
              about: [
                'Started coding at 8 years old. Created a video game at 16, sold 325.000 copies. Worked as developer, tech lead in telecom industry. Created a digital agency Adyax, sold in 2018 once reached 400 people. Created an open source project : GraphQL Portal, to help companies embrace the power of GraphQL.'
              ],
              topic_title: 'GraphQL : Beyond APIs, graph your enterprise.',
              topic_description: [
                'GraphQL is way more than just a language for APIs. It offers a unique way to describe your entire enterprise as a requestable graph. We\'ll see during this speech and through concrete examples how GraphQL can empower your company by reducing time & code, creating more robust apps, simplifying development and building trust in your organization.'
              ],
              linkedin: 'mtopolov',
              twitter: 'mtopolov'
            },
            {
              data_target: 'mehdiModal',
              image_url: 'mehdi-pro.jpg',
              name: 'Mehdi Medjaoui',
              job_title: 'Founder of apidays conferences',
              company: 'apidays conferences',
              position: 'Founder',
              media_card: 'mehdi-smc.jpg',
              about: [],
              about_special: '<span class="d-block mb-3">Mehdi Medjaoui is the founder of APIdays conferences series worldwide, that he started in 2012 in Paris. He is highly involved in the API community and API Industry, and is a current author, lecturer, consultant and investor in the API space. In 2011 he co-founded <a href="http://oauth.io/"  target="_blank">OAuth.io</a>, a SaaS OAuth API middleware for OAuth integration and implementation used by 40,000+ developers, that had been acquired in December 2017. His research involves publishing the API Industry Landscape and the yearly State of Banking APIs.',
              topic_title: 'The state of the API industry : tech, business and regulations',
              linkedin: 'mehdimedjaoui'
            },
            {
              data_target: 'morrisModal',
              image_url: 'morris-pro.jpg',
              name: 'Morris Matsa',
              job_title: 'APIGW Lead at IBM',
              company: 'IBM',
              position: 'APIGW Lead',
              media_card: 'morris-smc.jpg',
              topic_title: 'Securing GraphQL with Cost Directives',
              topic_description: [
                'Large public GraphQL endpoints have all advertised a notion of GraphQL cost for years, and various GraphQL servers and open source projects have implemented GraphQL cost calculations.  In 2021, an effort has begun to standardize how systems communicate GraphQL cost to each other, which has promise to dramatically ease securing these systems and thus opening up many more big public GraphQL endpoints.  Join us to learn about this effort, and how it can benefit you and your GraphQL strategy.'
              ],
              linkedin: 'mmatsa'
            },
            {
              data_target: 'naveenModal',
              image_url: 'naveen-pro.jpg',
              name: 'Naveen Nandan',
              job_title: 'Solutions Engineer at Confluent APAC',
              company: 'Confluent APAC',
              position: 'Solutions Engineer',
              media_card: 'naveen-smc.jpg',
              topic_title: 'Building Event-Driven APIs',
              topic_description: [
                'A workshop to walkthrough how to build event-driven APIs using ksqlDB on Confluent Platform'
              ],
              linkedin: 'navnandan'
            },
            {
              data_target: 'nickyModal',
              image_url: 'nicky-pro.jpg',
              name: 'Nicky Ng',
              job_title: 'Architect at IBM',
              company: 'IBM',
              position: 'Architect',
              media_card: 'nicky-smc.jpg',
              about: [
                'Nicky Ng is an experienced technical lead in IBM managing a number of successful innovative projects about Open API, Cloud and Automations.'
              ],
              topic_title: 'Next Stage for Open API at Banking Industry',
              topic_description: [
                'Following the Phase III & IV Open API implementation plan of the Hong Kong Monetary Authority (HKMA) released in May, the banks will further speed up their journeys to open up services and data for third-party service providers collaboration in order to support new use cases based on customer consents.  IBM, supporting open banking initiatives globally, is going to share the point-of-view and key solution elements for supporting the banking industry to achieve the success.'
              ],
              linkedin: 'nicky-ng-922793197'
            },
            {
              data_target: 'patrickModal',
              image_url: 'patrick-pro.jpg',
              name: 'Patrick C.S. Fan',
              job_title: 'CEO of beNovelty & Lead Organizer of apidays HK',
              company: 'beNovelty',
              position: 'CEO of beNovelty & Lead Organizer of apidays HK',
              media_card: 'patrick-smc.jpg',
              about: [
                'Patrick Fan is a serial entrepreneur and CEO of beNovelty Limited - an award-winning API first company. Patrick is a recognized thought leader in API space and the Hong Kong organizer & representatives of APIDays - the world\'s Leading API Conference Series. He is an active participant & speaker of major API Developer Communities in different regions. Patrick is current an entrepreneur-in-residency (EIR) of a venture capital and providing API advisory to various local startups & enterprise across sectors to adopt API first strategy. With his exceptional knowhow in Open API domain, Patrick is part of the Hong Kong API Startup Registration Scheme workgroup and is appointed by HKQAA as an associate trainer in API Cybersecurity & Information Security.'
              ],
              linkedin: 'patrickcsfan',
              twitter: 'patrickcsfan'
            },
            {
              data_target: 'philModal',
              image_url: 'phil-pro.jpg',
              name: 'Phil Nash',
              job_title: 'Developer Evangelist at Twilio',
              company: 'Twilio',
              position: 'Developer Evangelist',
              media_card: 'phil-smc.jpg',
              about: [],
              about_special: '<span class="d-block mb-3">Phil is a developer evangelist for <a href="https://www.twilio.com/" target="_blank">Twilio</a> and a <a href="https://developers.google.com/experts/people/phil-nash" target="_blank">Google Developer Expert</a> living in Melbourne, Australia. He loves working with Ruby or JavaScript to build web applications and tools to help developers. He once helped build a website that captured everyone\'s favourite sandwich fillings. He has too many GitHub repositories and you can find him working on some of them live on Twitch.</span><span class="d-block mb-3">Away from the keyboard, Phil listens to ska punk, hangs out with his miniature dachshund (also called Ruby), and is on a mission to discover the world\'s best beers.</span><span class="d-block">Phil tweets at <a href="https://twitter.com/philnash" target="_blank">@philnash</a>, and you can find him elsewhere online <a href="http://philna.sh" target="_blank">here</a>.</span>',
              topic_title: 'Better API DX with a CLI',
              topic_description: [
                'When you try to improve the experience for using your API you might consider a command line interface. CLIs allow developers to explore the API, automate API usage, and most importantly never leave the keyboard. If you have an OpenAPI spec you can even generate a CLI automatically. But replicating the API for the command line is the baseline, CLIs have so much more potential.',
                'In this talk we\'ll discuss how Twilio built a CLI for our API and the opportunities it gave us to improve the developer experience of working with the API. With the Twilio CLI we enhanced API endpoints with extra features, helped to demystify webhooks, and let the community create their own plugins to supercharge their experience.',
                'When done right, an API and a CLI are worth more than the sum of their parts.'
              ],
              linkedin: 'philnash',
              twitter: 'philnash'
            },
            {
              data_target: 'pricillaModal',
              image_url: 'pricilla-pro.jpg',
              name: 'Pricilla Bilavendran',
              job_title: 'Senior QA Engineer at Billennium IT Services (M) Sdn',
              company: 'Billennium IT Services (M) Sdn',
              position: 'Senior QA Engineer',
              media_card: 'pricilla-smc.jpg',
              about: [
                'Pricilla is currently working as Senior QA Engineer for Billennium IT Services (M) Sdn - Malaysia, with a decade of experience in Quality Assurance with multiple domains like Insurance, Healthcare, Fintech and Pharma. She is so keen and loves to handle APIs. Currently she is performing the Front End/API Automation Tests.',
                'She is a Postman Supernova and speaks at various events regarding APIs and Postman. She is helping many Test Engineers through her blogs, webinars and mentoring sessions for those who are willing to learn and upgrade their skill sets.',
                'She is a strong believer of Karma and very cautious about her actions. She feels blessed with what all she has right now.'
              ],
              topic_title: 'Test Data 101 for APIs',
              topic_description: [],
              topic_special: '<ul><li>Basics of Test Data</li><li>Approaches to generate Test Data</li><li>Test Data Importance for APIs</li><li>Mockaroo tool demo</li><li>Postman using Mockaroo URL to generate data on the fly</li></ul>',
              linkedin: 'pricilla-b',
              twitter: 'PricillaBelwin'
            },
            {
              data_target: 'ranaModal',
              image_url: 'rana-pro.jpg',
              name: 'Rana Peries',
              job_title: 'CTO of 101 Digital',
              company: '101 Digital',
              position: 'CTO',
              media_card: 'rana-smc.jpg',
              about: [
                'A commercially focused, business & technology leader and innovator with over 25 years of experience in financial services (retail banking, insurance, SME & corporate banking) in developed and emerging markets including Australia, Africa, Europe and Asia.',
                'Well versed in Strategy, Transformation, Digital Banking / Payments, Business Model & Technology Innovation, Program Delivery, Vendor Management, Building & Leading High Performance Teams.'
              ],
              topic_title: 'Driving Digital Customer Acquisition with Open Banking',
              topic_description: [
                'Open Banking is gaining momentum globally and is quickly becoming the standard for leading Banks & FinTechs worldwide. It is set to disrupt traditional banking business models, but can also pave the way to new and innovative business models that drive customer acquisition and new revenue streams.',
                'In this session, we will explore Open Banking business models, the threats and opportunities of Open Banking, real world Open Banking use cases, and most importantly, strategies for successful Open Banking implementations.'
              ],
              linkedin: 'ranaperies'
            },
            {
              data_target: 'richardModal',
              image_url: 'richard-pro.jpg',
              name: 'Richard Lord',
              job_title: 'Chief Information Officer, Commercial Banking – Asia Pacific at HSBC Hong Kong',
              company: 'HSBC Hong Kong',
              position: 'Chief Information Officer, Commercial Banking – Asia Pacific',
              media_card: 'richard-smc.jpg',
              topic_title: 'APIs – Trust, Commerce and Connection',
              topic_description: [
                'As market understanding and adoption of APIs continues at great pace, it is important to look beyond just the technology. APIs are a new conduit for connected business, so just as we do in the physical world, we need to look at what makes for good business. During this session I will talk about the need for clear commercial engagement and trust as key elements for a success connected business model.'
              ],
              linkedin: 'richardalord'
            },
            {
              data_target: 'robModal',
              image_url: 'rob-pro.jpg',
              name: 'Rob Dickinson',
              job_title: 'Co-founder, CTO of Resurface Labs Inc.',
              company: 'Resurface Labs Inc.',
              position: 'Co-founder, CTO',
              media_card: 'rob-smc.jpg',
              about: [
                'My comfort zone is right at the intersection between people and technology. My technical and leadership skills were honed at Intel, Dell, and Quest Software, but my true passion is listening and learning from customers. After 15 years of experience in the APM market, I started Resurface with the idea that real customer empathy should inform and infuse everything we deliver.'
              ],
              topic_title: 'Shifting out of triage mode with a failing API',
              topic_description: [
                'When an API fails, users can\'t just call in and get help. It\'s extremely difficult. The whole idea of waiting for things to break in production and then dealing with the fallout of angry customers and lost revenue – accepting some level of failure - is Stone Age thinking. How many problems are you tracking right now that you heard about but can\'t reproduce? We can actually do much better. How to responsibly gather the API data that makes it possible to know everything. In this session, we’ll discuss how to shift out of triage mode to get ahead of problems. API-first architecture means understanding what information to capture and track, but more importantly, how to quickly find and manage information on user actions and security threats. Even for those old, failing APIs.'
              ],
              linkedin: 'robfromboulder'
            },
            {
              data_target: 'rosemaryModal',
              image_url: 'rosemary-pro.jpg',
              name: 'Rosemary Missier',
              job_title: 'Product Manager at Xero',
              company: 'Xero',
              position: 'Product Manager',
              media_card: 'rosemary-smc.jpg',
              about: [
                'And I believe in living it up, wear my heart on my sleeve & love to strike a good conversation.',
                'I am a decisive, adaptive and energetic human with years of Software Engineering experience in varied domains. When I get the time, you\'ll find me teaching Computer Science in uni or speaking at conferences.',
                'A firm believer that art and tech need to intersect. So you\'ll notice my creativity in tech stems from my interest in paintings and dance. This allows me to be innovative, experimental and take the path less traveled!'
              ],
              topic_title: 'Confessions of a Product Geek : My First API',
              topic_description: [
                'This talk will discuss best practices and anti-patterns in API product ownership and product management. They\'re based on my experiences of working with APIs in a few different companies and the knowledge I\'ve gained from being a Product Owner and also having worked with many others in the same field.'
              ],
              topic_special: '<span class="d-block font-weight-bold" >Learning Outcomes</span><ul class="mb-3"><li>API Product Management</li><li>Stakeholder Engagement</li><li>Best practices, Common traps: myths and anti-patterns</li><li>101 for aspiring API Product Owners/Managers</li></ul>',
              linkedin: 'missier'
            },
            {
              data_target: 'shiroshicaModal',
              image_url: 'shiroshica-pro.jpg',
              name: 'Shiroshica Kulatilake',
              job_title: 'Director - Solutions Architecture at WSO2',
              company: 'WSO2',
              position: 'Director - Solutions Architecture',
              media_card: 'siddhant-smc.jpg',
              about: [
                'In her role in the Solutions Architecture team at WSO2, Shiro works closely with customers from various business domains and provides solutions-based consultancy as to how WSO2 technology can be used effectively to achieve an organization’s business objectives. In a previous role at WSO2, Shiro was a part of the cloud technical group, where she worked with Private PaaS and AppFactory teams. She has 20 years of experience in the IT industry with experience in building telecom billing systems and enterprise search systems.'
              ],
              topic_title: 'Hybrid API Management Strategies',
              linkedin: 'shiroshica-kulatilake-b4239a9'
            },
            {
              data_target: 'siddhantModal',
              image_url: 'siddhant-pro.jpg',
              name: 'Siddhant Agarwal',
              job_title: 'Developer Relations Lead at	Zwitch.io (Open Financial Technologies Private Limited)',
              company: 'Zwitch.io (Open Financial Technologies Private Limited)',
              position: 'Developer Relations Lead',
              media_card: 'siddhant-smc.jpg',
              about: [],
              about_special: '<span class="d-block mb-3">Siddhant Agarwal currently works with Open Financial Technologies as "Developer Relations Lead" where he is building <a href="http://zwitch.io/" target="_blank">Zwitch.io</a>, an embedded finance platform which helps businesses create new avenues by embedded financial services using Zwitch\'s banking API\'s. Previously, he has worked as Program Coordinator with the Developer Relations team at Google in India. As part of his role at Google, he had led the Developer Student Clubs program, TensorFlow User Groups, Google Developer Groups, and Google Developer Experts program in India. He also designed and executed the initiative “Build for Digital India” in 2019 launched in collaboration with the Ministry of Electronics & Information Technology, Govt. of India which witnessed 7K+ students participating and building solutions that “Solve for India”. Siddhant is one of ACM’s Distinguished Speakers among 200+ speakers worldwide and 20+ in India. A design thinker at heart, he loves working with startups and helping them scale in UX and improve their designs.</span><span class="d-block mb-3">With over 6 years of industry experience, he has found his passion in ed-tech initiatives, community building, design innovation, startup ecosystem and building for the next billion users. For his contributions in community building, he was also nominated as one of the finalists of CMX Community Industry Awards 2021 for "Community Professional of the Year for a Developer Relations Community" and "Community Professional of the Year" categories. Being an avid public speaker, Siddhant has spoken at more than 200+ national and international forums impacting 90K+ students/developers/individuals in the last 5+ years.</span>',
              topic_title: 'API Economy in Financial Services ',
              topic_description: [
                'In today’s highly competitive environment, organizations are continuously seeking to increase their growth opportunities, improve their client experience, reduce costs and enhance operational efficiency. Institutions across financial services are embracing APIs to meet these challenges, and to improve the flow of data and information across operations. In turn, APIs open communication channels between programs and, in doing so, enable digital transformation without a full overhaul of legacy infrastructure.',
                'As fintech companies continue to develop modern technology solutions, APIs allow organizations to seamlessly integrate these updates within existing operations. In today’s world, where there is an expectation that the speed of innovation no longer sacrifices customer satisfaction or experience, it’s important for traditional institutions to be able to keep pace.',
                'This talk covers the relevance of APIs in the financial industry, case studies and how businesses can embed financial services in their existing products and become fintech ready.'
              ],
              linkedin: 'sidagarwal04',
              twitter: 'sidagarwal04'
            },
            {
              data_target: 'simenModal',
              image_url: 'simen-pro.jpg',
              name: 'Simen Fossnes',
              job_title: 'CTO & Co-Founder of Diggit',
              company: 'Diggit',
              position: 'CTO & Co-Founder',
              media_card: 'simen-smc.jpg',
              about: [
                'System-wide designer, architect & engineer. I\'m currently working to create and share the components required for a new & radically improved education system, together with the rest of the team at Diggit.'
              ],
              topic_title: 'Docs, Codegen, Discovery & Governance in a Subscribe/Notify World',
              topic_description: [
                'AsyncAPI is an open source initiative that seeks to improve the current state of Event-Driven Architectures (EDA). The long-term goal is to make working with EDAs as easy as it is to work with REST APIs. AsyncAPI is like OpenAPI, but for the subscribe/notify paradigm (rather than request/response). This will be an introduction, overview and demo of the world of possibilities that opens after adopting AsyncAPI.'
              ],
              linkedin: 'simenfossnes',
              twitter: 'SimenFossnes'
            },
            {
              data_target: 'simonModal',
              image_url: 'simon-pro.jpg',
              name: 'Simon Redfern',
              job_title: 'CEO and Founder of TESOBE ',
              company: 'TESOBE',
              position: 'CEO and Founder',
              media_card: 'simon-smc.jpg',
              about: [
                'Pioneering Open Banking since February 2010, Simon is the CEO of TESOBE and founder of the Open Bank Project, the open source API platform for banks. Simon studied electronic engineering in Manchester in the early \'80s where he focused on real-time programming and digital systems - and he subsequently worked as a software developer, architect, sysadmin, and DBA on numerous web / mobile platforms both large and small. A hacker at heart, Simon is also a composer and enjoys interdisciplinary creative collaborations.',
                'Simon is a keen supporter of open source and open data believing they are a sustainable force for social good.'
              ],
              topic_title: 'TradFi and DeFi or, Where the Land meets the Sea.',
              topic_description: [
                'Most people with office computer experience have an idea of what a database is. They’ll have seen data entry screens and spreadsheets, and they know that the data resides either on their local computer or on a company server. The cloud has made data storage more abstract, trouble-free and scalable - but the hosting provider and data manager are still in charge. Similarly with traditional financial products: Consumers expect their bank account or insurance policy to be held only by the legal entity they signed their contract with. Their data, transactions, and legal agreements are guarded by large databases with long (barely) human-readable contracts.',
                'Blockchain and smart contracts change everything. They are like the sea to a land lover. There are different rules on the surface and under the sea, which may seem scary from dry land. But there’s most definitely life, and it’s evolving fast.',
                'Simon Redfern, CEO of TESOBE and founder of the Open Bank Project will explore DAO’s (Distributed Autonomous Organisations), Oracles (not the database company), how the land lovers can meet the seafarers - and what banks can offer and consume at the beach bar!'
              ],
              linkedin: 'simon-redfern-78a43b1',
              twitter: 'simsysims'
            },
            {
              data_target: 'simonwModal',
              image_url: 'simonw-pro.jpg',
              name: 'Simon Wong',
              job_title: 'Principal Strategic Client Architect at Mulesoft',
              company: 'Mulesoft',
              position: 'Principal Strategic Client Architect',
              media_card: 'simonw-smc.jpg',
              topic_title: 'Managing multiple APIs across any environment with MuleSoft',
              topic_description: [
                'It’s a multi-cloud world and now more than ever critical to be able to manage API lifecycles across any environment, for all APIs. This workshop will walk through how to get started with MuleSoft and how to leverage OAS 3.0, AsyncAPI, and GraphQL to design in any language, implement event-driven architecture faster, and consume multiple APIs in a single request.'
              ],
              linkedin: 'skwongus'
            },
            {
              data_target: 'snehalModal',
              image_url: 'snehal-pro.jpg',
              name: 'Snehal Chakraborty',
              job_title: 'Cloud Integration Architect at Accenture Netherlands B.V.',
              company: 'Accenture Netherlands B.V.',
              position: 'Cloud Integration Architect',
              media_card: 'snehal-smc.jpg',
              about: [
                'Experienced professional in Cloud Integration. Have been part of API programs for leading Dutch banks, European retailer and European Insurance provider. Have experience in Cloud Integration platforms like APIGEE, Mulesoft, Oracle API Cloud Service and Dell Boomi.'
              ],
              topic_title: 'Headless API Management',
              topic_description: [
                'The idea is to allow an organization to have multiple API Management platforms due to multi cloud strategies , choices across regional subsidiaries, align with IT needs of the new world, But in order to manage this a platform enablement team is still required which can have an oversight of usage across the landscape of multiple platforms and implement governance along with change and operations. This team should be able to provide platforms these towards API product teams in a vendor agnostic way using a headless management plane (plugin management APIs from the individual platforms). This idea is still at concept phase and has not been implemented yet.',
                'The presentation will explain:'
              ],
              topic_special: '<ul class="mb-3"><li>Why this is becoming important.</li><li>What needs to be done</li><li>What will the management plane look like</li><li>What are the possible challenges</li><li>Do\'s and Don\'ts</li></ul>',
              linkedin: 'snehal-chakraborty'
            },
            {
              data_target: 'stuartModal',
              image_url: 'stuart-pro.jpg',
              name: 'Stuart Seow',
              job_title: 'Senior Solutions Consultant at New Relic',
              company: 'New Relic',
              position: 'Senior Solutions Consultant',
              media_card: 'stuart-smc.jpg',
              topic_title: 'Observing what\'s powering your APIs',
              topic_description: [
                'This workshop goes into details of how you can get comprehensive visibility into the application and infrastructure services powering your APIs - simple, powerful observability.'
              ],
              linkedin: 'stuartseow'
            },
            {
              data_target: 'tharikaModal',
              image_url: 'tharika-pro.jpg',
              name: 'Tharika Madurapperuma',
              job_title: 'Senior Software Engineer at WSO2',
              company: 'WSO2',
              position: 'Senior Software Engineer',
              media_card: 'tharika-smc.jpg',
              about: [
                'Tharika is presently employed with WSO2 as a Senior Software Engineer in the API Management domain. She has contributed to the development and maintenance of numerous features for the WSO2 API Manager Product, including integration first development and user interface design, with her 4 years of experience in the field. While being passionate about sharing her knowledge with others, she enjoys learning new things. She enjoys learning new things as much as she enjoys sharing her knowledge with others.'
              ],
              topic_title: 'OAS to Managed API in Seconds',
              topic_description: [
                'A robust and effective API-driven business requires a smoothly running CI/CD pipeline for business APIs. WSO2 API Manager delivers the tools you need to make this a reality and bring your APIs into production as quickly as feasible. In this workshop, we\'ll go over how to set up a Git-based CI/CD pipeline for deploying your OAS as a Managed API in production with ease.'
              ],
              linkedin: 'tharikamadurapperuma'
            },
            {
              data_target: 'trevorModal',
              image_url: 'trevor-pro.jpg',
              name: 'Trevor Cheung',
              job_title: 'MD, Head of Digital & Ecosystems & Innovation, Institutional Banking Group, DBS Hong Kong and GBA at DBS Bank',
              company: 'DBS Bank',
              position: 'MD, Head of Digital & Ecosystems & Innovation, Institutional Banking Group, DBS Hong Kong and GBA',
              media_card: 'trevor-smc.jpg',
              topic_title: 'Dissecting the myths of API',
              linkedin: 'trevorcheung'
            },
            {
              data_target: 'uliModal',
              image_url: 'uli-pro.jpg',
              name: 'Uli Hitzel',
              job_title: 'Chief Architect of Care / Raffles Medical Group',
              company: 'Care / Raffles Medical Group',
              position: 'Chief Architect',
              media_card: 'uli-smc.jpg',
              about: [
                'Uli has been working with data engineering & distributed systems since the early days of the internet. Previously with companies including Microsoft, Red Hat, Yahoo! and IBM, he now works on building the healthcare platform of the future as the Chief Architect at Care.'
              ],
              topic_title: 'APIs in Healthcare – Tales From The Field',
              topic_description: [
                'Imagine you\'ve spent quite a few years working for software vendors, educating customers on APIs, modern application design, and digital transformation. But then you switch sides and find yourself working with one of the largest private healthcare providers in APAC to apply what you\'ve learned and face the reality in the middle of a pandemic — that\'s my story.',
                'In this session we talk about APIs that help fight viruses, innovation but also fragmentation in the healthcare sector, and how interoperability and ecosystem collaboration are the only plausible way forward for the industry.'
              ],
              linkedin: 'uhitzel'
            }
          ]
        }
      ],
      sponsor_list: [
        {
          type: 'GOLD SPONSORS',
          css_class: 'w-100 w-md-80 w-lg-60 mx-auto',
          sponsor: [
            {
              css_class: 'col-sm-6 mb-1 mb-sm-0',
              image_css: 'max-w-27rem',
              image_url: 'confluent.png'
            },
            {
              css_class: 'col-sm-6',
              image_css: 'max-w-19rem',
              image_url: 'ibm.png'
            }
          ]
        },
        {
          type: 'SILVER SPONSORS',
          css_class: 'w-100 w-md-80 w-lg-60 mx-auto',
          sponsor: [
            {
              css_class: 'col-sm-4 mb-1 mb-sm-0',
              image_css: 'max-w-19rem',
              image_url: 'mulesoft.png'
            },
            {
              css_class: 'col-sm-4',
              image_css: 'max-w-19rem',
              image_url: 'new-relic.png'
            },
            {
              css_class: 'col-sm-4',
              image_css: 'max-w-19rem',
              image_url: 'softwareag.png'
            }
          ]
        },
        {
          type: 'BRONZE SPONSORS',
          css_class: 'w-100 w-md-60 w-lg-50 mx-auto',
          sponsor: [
            {
              css_class: 'col-sm-12',
              image_css: 'max-w-15rem',
              image_url: 'amadeus.png'
            }
          ]
        },
        {
          type: 'COMMUNITY SPONSORS',
          css_class: 'w-100 w-md-80 w-lg-60 mx-auto',
          sponsor: [
            {
              css_class: 'col-sm-6 mb-1 mb-sm-0',
              image_css: 'max-w-27rem',
              image_url: 'hkcs.png'
            },
            {
              css_class: 'col-sm-6',
              image_css: 'max-w-27rem',
              image_url: 'hkstpsaa.png'
            }
          ]
        }
      ]
    }
  },
  computed: {
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-inline-video-player').each(function() {
      new HSVideoPlayer($(this)).init()
    })
    // this.loadScriptRecapSubscribeForm('https://js.hsforms.net/forms/v2.js', () => {
    //   const secondScript = document.createElement('script')
    //   secondScript.innerHTML = `
    //     hbspt.forms.create({
    //       portalId: "7091000",
    //       formId: "4daac438-a701-4a30-89de-fefdd7a7306f"
    //     });
    //   `
    //   this.$refs.recapSubscribeForm.appendChild(secondScript)
    // })
    // window.addEventListener('message', event => {
    //   if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
    //     this.isSubmitted = true
    //   }
    // })
    $('.js-fancybox').each(function() {
      $.HSCore.components.HSFancyBox.init($(this))
    })
  },
  // methods: {
  //   loadScriptRecapSubscribeForm(url, callback) {
  //     var script = document.createElement('script')
  //     script.type = 'text/javascript'
  //     script.src = url
  //     script.onreadystatechange = callback
  //     script.onload = callback
  //     this.$refs.recapSubscribeForm.appendChild(script)
  //   }
  // },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Apidays Live Hong Kong 2021 | %s',
      link: [
        { rel: 'canonical', href: 'https://hub.openapihub.com/community-updates/20210825-Apidays-Live-Hong-Kong-2021' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Apidays Live Hong Kong 2021 | beNovelty' },
        { property: 'og:description', content: 'August 25 & 26, 2021 - API Ecosystem & Data Interchange | Apidays Hong Kong 2021 is going to bring together leading API experts both local and international to share their seasonal insights and lessons learnt in driving the team and business under the “new normal”.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/apidays-live-hongkong-2021.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/events/apidays-live-hongkong-2021' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/fancybox/dist/jquery.fancybox.min.css");
</style>

